// bg-position(x,y) width height
$icons: (
	'aviao': -88px -56px 18px 17px,
	'caminhao': -26px -88px 18px 13px,
	'carrinho': -88px 0px 18px 18px,
	'envelope': -54px -88px 16px 12px,
	'facebook': 0px -44px 33px 34px,
	'fone': -116px 0px 15px 15px,
	'html': -43px -44px 32px 32px,
	'instagram': 0px 0px 34px 34px,
	'linkedin': -44px 0px 34px 34px,
	'lixo': -116px -25px 11px 12px,
	'login': 0px -88px 16px 18px,
	'lupa': -88px -28px 18px 18px,
	'next': -116px -63px 6px 10px,
	'prev': -116px -83px 6px 10px,
	'setinha': -116px -47px 11px 6px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	background-size: 131px 106px;

	display: inline-block;
	vertical-align: middle;

}

.icon__mask{
	mask-image: url('../images/sprites.png');
	mask-repeat:no-repeat;
	mask-size: 131px 106px;

	display: inline-block;
	vertical-align: middle;
	background-color: currentColor;
}

@each $icon,$value in $icons {
	.#{$icon} {
		width: nth($value,3);
		height: nth($value,4);
		$sprite-position: #{nth($value,1)} #{nth($value,2)};

		&.icon{
			background-position: $sprite-position;
		}

		&.icon__mask{
			mask-position: $sprite-position;
		}
	}
}
