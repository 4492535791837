.rodape__conteudo{
	padding-top: 80px;
	background-color: #193C34;
	color: #fff;

	.grid__rodape{
		@include media-breakpoint-up (lg) {
			display: grid;
			grid-template-columns: minmax(auto, 270px) minmax(auto, 270px) minmax(auto, 130px) minmax(auto, 170px) minmax(auto, 200px);
			column-gap: 20px;
		}
		padding-bottom: 46px;
		border-bottom: 1px solid #fff;
	}
}
.rodape__texto{
	text-align: center;
	font-size: 14px;
	line-height: 18px;
	padding-bottom: 30px;
	padding-top: 30px;
}
.rodape__titulo{
	font-family: interbold;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 15px;
	padding-top: 32px;
}
.rodape__logo{
	margin-bottom: 10px;
}
.rodape__desc{
	margin-bottom: 30px;
	font-size: 14px;
	line-height: 16px;
}
.rodape__sociais{
	display: flex;
	align-items: center;

	a{
		display: block;
		min-width: 34px;
		margin-right: 5px;
	}
}
.contato__rodape__titulo{
	font-family: interbold;
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 5px;
}
.contato__rodape{
	margin-bottom: 25px;
}
.contatos__rodape{
	display: flex;
	justify-content: space-between;
}
.rodape__nav{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-size: 14px;
	line-height: 16px;

	a{
		display: inline-block;
		margin-bottom: 8px;
	}
}
.creditos{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 0px;
	font-size: 12px;
	line-height: 16px;
}
.rodape__creditos{
	background-color: #4b5c56;
	color: #fff;
}
.gv8__box{
	display: flex;
	align-items: center;

	svg{
		margin-left: 5px;
	}
}
@include media-breakpoint-down (md) {
	.creditos{
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
	.rodape__logo,
	.rodape__titulo,
	.rodape__desc,
	.pagamento__img,
	.contato__rodape{
		text-align: center;
	}
	.rodape__nav,
	.rodape__sociais,
	.contatos__rodape{
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	.contatos__rodape{
		flex-direction: column;
	}
}
