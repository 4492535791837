.blog__card{
	display: block;
	max-width: 830px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 30px;
	border-bottom: 2px solid #d1d1d1;
	margin-bottom: 30px;

	&:hover{
		text-decoration: none;
		.blog__card__btn{
			span{
				text-decoration: underline;
			}
		}
	}
}
.blog__card__titulo{
	font-family: interbold;
	font-size: 20px;
	line-height: 24px;
	color: #353535;
	margin-bottom: 20px;
}
.blog__card__data{
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 20px;
}
.blog__card__desc{
	--linhas: 3;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 16px;
}
.blog__card__btn{
	display: flex;
	align-items: center;
	color: #bc022b;
	font-family: interbold;

	&:after{
		display: flex;
		align-items: center;
		justify-content: center;
		content: "\f054";
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		font-size: 10px;
		line-height: 10px;
		width: 16px;
		height: 16px;
		border: 1px solid #bc022b;
		border-radius: 50%;
		color: #bc022b;
		margin-left: 5px;
	}
}
.mais__lidas__header{
	font-family: 'interbold';
	font-size: 20px;
	line-height: 24px;
	color: #fff;
	background: #6a1453;
	background: linear-gradient(0deg, rgba(#6a1453,1) 0%, rgba(#34215f,1)  100%);
	display: flex;
	justify-content: flex-start;
	align-items: center;
    padding: 17px 18px 15px 18px;
	text-align: center;
}
.mais__lidas__listagem{
	background-color: #e7e7e9;
	padding-left: 15px;
	padding-right: 15px;

	.blog__detalhe__card{
		display: flex;
		align-items: center;
		padding: 20px 0px;
		border-bottom: 2px solid #c7c7c7;

		.blog__lidas__imagem{
			min-width: 30%;
			max-width: 30%;
		}
		.blog__lidas__conteudo{
			padding-left: 10px;

			.blog__card__titulo{
				--linhas: 3;
				font-size: 14px;
				line-height: 16px;
				margin-bottom: 0px;
			}
		}
	}
}
.carousel-nav-lidas{
	padding: 20px;
	display: flex;
	align-items: center;
	font-size: 20px;
	line-height: 20px;
	justify-content: center;

	a{
		display: block;
		margin: 0px 10px;
		transition: all 0.3s linear;

		&:hover{
			opacity: 0.5;
		}
	}
}
@include media-breakpoint-down (sm) {
	.blog__interna{
		.row{
			.col-lg-9{
				order: 2;
			}
			.col-lg-3{
				order: 1;
				margin-bottom: 40px;
			}
		}
	}
}
.titulo__blog__interna{
	padding: 20px 0px 40px;
	max-width: 700px;
	width: 100%;
	text-align: center;
	font-size: 30px;
	line-height: 34px;
	color: #353535;
	font-family: 'interbold';
	margin-left: auto;
	margin-right: auto;
}
.data__interna{
	text-align: center;
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 30px;

	&:after{
		width: auto;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		margin-left: 20px;
		height: 2px;
		background: #353535;
		content: "";
		display: block;
		border-radius: 2px;
	}
	&:before{
		width: auto;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		margin-right: 20px;
		height: 2px;
		background: #353535;
		content: "";
		display: block;
		border-radius: 2px;
	}
}
