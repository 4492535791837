@font-face {
    font-family: 'interbold';
    src: url('../fonts/interbold/Inter-Bold.eot');
    src: url('../fonts/interbold/Inter-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/interbold/Inter-Bold.woff2') format('woff2'),
        url('../fonts/interbold/Inter-Bold.woff') format('woff'),
        url('../fonts/interbold/Inter-Bold.ttf') format('truetype'),
        url('../fonts/interbold/Inter-Bold.svg#Inter-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'intersemibold';
    src: url('../fonts/intersemibold/Inter-SemiBold.eot');
    src: url('../fonts/intersemibold/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/intersemibold/Inter-SemiBold.woff2') format('woff2'),
        url('../fonts/intersemibold/Inter-SemiBold.woff') format('woff'),
        url('../fonts/intersemibold/Inter-SemiBold.ttf') format('truetype'),
        url('../fonts/intersemibold/Inter-SemiBold.svg#Inter-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'interregular';
    src: url('../fonts/interregular/Inter-Regular.eot');
    src: url('../fonts/interregular/Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/interregular/Inter-Regular.woff2') format('woff2'),
        url('../fonts/interregular/Inter-Regular.woff') format('woff'),
        url('../fonts/interregular/Inter-Regular.ttf') format('truetype'),
        url('../fonts/interregular/Inter-Regular.svg#Inter-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

