.login__form,
.login--cadastro__form{
    .form-group{
        margin-bottom: 10px;
    }
}
.titulo__interna__login{
	font-family: 'interbold';
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 46px;
}
.login__desc{
    margin-bottom: 21px;
    padding-top: 5px;
    font-family: 'interregular';
    color: #353535;
}
.esqueci__link{
    font-family: 'interregular';
    font-size: 13px;
    line-height: 15px;
    color: #353535;
    padding-top: 7px;
    display: inline-flex;
    position: relative;
    align-items: center;
    transition: all 0.3s linear;
}
.login__btn{
    padding-top: 5px;
}
.login--cadastro__btn{
    padding-top: 30px;
}
.titulo__login{
    font-family: 'interbold';
    color: #353535;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 22px;
}
@include media-breakpoint-down (md) {
    .login__btn{
        margin-bottom: 40px;
    }
}
