.bg__menu{
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	background-color: rgba(#666666, 0.6);
	animation: fadeIn 0.5s linear;
	z-index: 1050;

	&.hide{
		animation: fadeOut 0.5s linear;
	}
}
.lista__todas__categorias{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.segura__carrinho__topo{
	position: relative;
	display: flex;
	align-items: center;

	.icon__mask{
		transition: all 0.3s linear;
	}

	a,
	button{
		&:hover{
			.icon__mask{
				color: #af1a16;
			}
		}
	}

	.carrinho__btn__topo{
		display: flex;
		align-items: center;
		position: relative;

		.carrinho__icone__topo{
			margin-left: 0px;
		}
	}
}
.segura__pesquisa{
	width: 100%;
	max-width: 375px;
}
.sociais__topo{
	display: flex;
	justify-content: center;
	font-size: 14px;
	line-height: 14px;
	align-items: center;

	a{
		display: inline-block;
		margin: 5px;
		transition: all 0.3s linear;

		&:hover{
			color: #2b3f38;
			text-decoration: none;
		}
	}
}
.avaliacoes__quant{
	margin-right: 5px;
}
.produto__detalhe__box__titulo{
	background-color: #e4e4e4;
	color: #666666;
	padding: 10px 32px;
	font-family: interbold;
}
.topo__pesquisa{
    position: fixed;
    top: -150px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1080;
    max-width: 900px;
    width: 90%;
    transition: all 0.5s linear;

    &.shown{
        top: 48vh;
    }
}
.lista__todas__categorias{
	background-color: #fff;
	border-radius: 10px;
}
.btn__toggle__categ{
	display: flex;
	align-items: center;
	background-color: transparent;
	justify-content: space-between;
	border: none;
	color: #fff;
	height: 50px;
	position: relative;
	z-index: 40;
	padding: 10px 15px;
	font-size: 14px;
	line-height: 14px;
	width: 100%;
	max-width: 352px;
	background-color: #2b3f38;
	border-radius: 24px 24px 0px 0px;

	&:after{
		width: 10px;
		height: 6px;
		background-image: url('../images/seta.png');
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center center;
		content:'';
		display: block;
		z-index: -1;
	}
}
.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;

	.dropmenu{
		list-style: none;
		padding: 0px;
		margin: 0px;

		a{
			display: block;
			padding: 10px;
			background-color: #2b3f38;
			color: #fff;
			font-family: 'interregular';
			border-bottom: 1px solid #2b3f38;
		}
	}
}
.pesquisa__form{
    display: flex;
    overflow: hidden;
    background-color: #fff;
    align-items: center;
	border-radius: 25px;
	border: 1px solid #2b3f38;

    .pesquisa__btn{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:#2b3f38;
        color: #fff;
        min-width: 40px;
        width: 40px;
        height: 40px;
        border: none;
		border-radius: 50%;
		position: relative;
		margin: 5px;
    }
    .pesquisa__input{
        display: block;
        width: 100%;
        font-family: 'interregular';
        font-size: 13px;
        line-height: 15px;
        padding: 18px 14px 17px 14px;
        border: none;
        background-color: #fff;
        color: #666666;
        border: none;
        border-radius: 25px;

        &::placeholder{
            color: #666666;
        }

        &:focus{
            outline: none;
        }
    }
    label{
        display: block;
        width: 100%;
        margin-bottom: 0px;
    }
}

.encerrar__item{
    padding: 10px 10px 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .encerrar__link{
        width: 198px;
        max-width: 100%;
        height: 50px;
        background-color: #a60000;
        color: #fff;
        font-family:'interbold';
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
		padding-top: 2px;
        transition: all 0.3s linear;
		position: relative;


		span{
			position: relative;
			z-index: 200;
		}

		@include media-breakpoint-down(md) {
            height: 28px;
			border-radius: 14px;
        }

        &:hover{
            color: #fff;
            text-decoration: none;
			background-color: #7C0000;
        }
    }
}
.logado__nome{
    padding: 28px 20px 22px 20px;
	background: #007d3a;
    color: #fff;
    font-family: 'interbold';
    font-size: 16px;
    line-height: 16px;
	text-align: center;

	@include media-breakpoint-down (md) {
        padding: 12px 6px 10px 6px;
    }
}
.logado__item{
    padding: 0px 20px;
	background-color: #fff;

    .logado__link{
        display: block;
        padding: 18px 0px 18px 0px;
        border-bottom: 1px solid rgba(#666666, 0.1);
        font-size: 14px;
        line-height: 14px;
        font-family: 'interregular';
        color: #666666;

        &:hover{
            color: #007d3a;
			text-decoration: underline;
        }

		@include media-breakpoint-down (md) {
            padding: 10px 6px 10px 6px;
        }
    }
}
.menu__item{
	&.menu__item--active{
		.menu__link{
			color: #d64d00 !important;
		}
	}
}
.dropdown-menu{
	border: none;
	padding: 0px;
	margin: 0px;
}
.segura__carrinho__topo{
	font-family: 'interregular';
	font-size: 14px;
	line-height: 16px;
	color: #666666;

	a{
		display: flex;
		align-items: center;

		&:hover{
            text-decoration: none;
			.carrinho__icone{
				box-shadow: 0px 0px 0px 3px rgba(#2b3f38, 0.5);
			}
        }
	}
	.carrinho__icone{
		position: relative;
		z-index: 100;
	}
}
.logado__toggler{
	display: flex;
	align-items: center;
	border: none;
	background-color: transparent;
	font-size: 14px;
	line-height: 16px;
	color: #666666;
	padding: 5px 0px;

	&:hover{
		text-decoration: none;
		.login__icone{
			&:before{
				opacity: 1;
			}
		}
	}

	&:focus{
		outline: none;
	}
}
.login__icone,
.carrinho__icone{
	margin-right: 6px;
	width: 40px;
	height: 40px;
	min-width: 40px;
	background-color: #2b3f38;
	color: #fff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	transition: all 0.3s linear;

	span{
		position: relative;
		z-index: 100;
	}
}
.segura__login__topo{
	font-size: 14px;
	line-height: 16px;
	color: #666666;
	position: relative;


    .login__toggler{
        display: flex;
        align-items: center;

        &:hover{
            text-decoration: none;
			.login__icone{
				box-shadow: 0px 0px 0px 3px rgba(#2b3f38, 0.5);
			}
        }
    }
    .dropdown{
        display: none;

		&:hover{
            text-decoration: none;
			.login__icone{
				box-shadow: 0px 0px 0px 3px rgba(#2b3f38, 0.5);
			}
        }
    }

    &.logado{
        .login__toggler{
            display: none;
        }
        .dropdown{
            display: block;
        }
    }
}
@include media-breakpoint-up (lg) {
	.main__logo{
		margin-right: 20px;
	}
	.segura__right{
		display: flex;
		align-items: center;

		& > * {
			margin-left: 30px;
		}
	}
	.main__cima{
		background-color: #fff;
		color: #2b3f38;
		padding: 10px 0px 2px;
		border-bottom: 1px solid #d1d1d1;
		.container{
			display: flex;
			justify-content: flex-start;
		}
	}
	.topo{
		position: relative;
		z-index: 800;
	}
	.mobile__icone{
		display: none !important;
	}
	.topo__mobile{
		display: none;
	}
	.segura__contato{
		display: flex;
		align-items: center;
		font-size: 14px;
		line-height: 16px;

		.contato__topo{
			display: flex;
			align-items: center;
			margin-right: 30px;
			font-size: 13px;
			line-height: 15px;

			.contato__topo__icone{
				font-size: 18px;
				line-height: 18px;
				margin-right: 5px;
				margin-bottom: 5px;
			}
		}
	}

	.logado__toggler{
		&:after{
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            border-bottom: 15px solid #007d3a;
            content: '';
            position: absolute;
            right: 136px;
            bottom: -20px;
            display: none;
			z-index: 100;
        }

        &[aria-expanded="true"] {
            &:after{
                display: block;
            }
        }
	}
	.main__meio{
		background-color: #fff;
		color: #666666;
		padding: 20px 0px 22px 0px;
		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;

			.main__pesquisa{
				flex-grow: 1;
				max-width: 450px;

				@include media-breakpoint-only (lg) {
					max-width: 300px;
				}
			}
		}
	}
	.segura__categorias{
		.container{
			display: flex;
			position: relative;
		}

		.main__menu{
			display: flex;
			justify-content: space-around;
			align-items: center;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			font-size: 14px;
			line-height: 14px;
			background-color: #ededed;
			color: #666666;
			flex-grow: 1;
		}
	}
	.categoria__expanded{
		position: absolute;
		left: 15px;
		top: 100%;
		max-width: 352px;
		width: 100%;
		z-index: 600;
		background-color: #2b3f38;
		border-radius: 0px 0px 15px 15px;
		padding-bottom: 20px;
		box-shadow: 0px 0px 5px rgba(#000, 0.4);

		.lista__todas__categorias{
			max-height: calc(100vh - 250px);
			overflow-y: auto;
		}

		.dropdown__controls{
			position: relative;
			button{
				position: absolute;
				right: 30px;
				top: calc(50% - 12px);
				background-color: #2b3f38;
				color: #fff;
				border: none;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0px;
				font-size: 12px;
				line-height: 12px;
				transition: all 0.3s linear;
				padding: 5px 2px 3px 3px;

				&[aria-expanded="true"]{
					transform: rotate(90deg);
				}
			}
		}

		.dropmenu{
			list-style: none;
			background-color: #2b3f38;
			color: #fff;
			padding-left: 20px;
			padding-right: 20px;

			li{
				&:last-child{
					a{
						border-bottom: none;
					}
				}
			}

			a{
				display: block;
				padding: 10px;
				border-bottom: 1px solid #fff;
			}
		}

		.categoria__link{
			display: flex;
			padding: 20px 22px 20px 21px;
			position: relative;
			font-family: interbold;
			font-size: 14px;
			line-height: 14px;
			width: 100%;

			&:after {
				width: calc(100% - 40px);
				left: 20px;
				bottom: 0px;
				position: absolute;
				background-color: #d1d1d1;
				content: '';
				height: 1px;
			}
		}
	}
	.logado__menu{
		min-width: 256px;
        max-width: 100%;
        left: unset !important;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#666666, 0.6);
		transform: unset !important;
		top: calc(100% + 20px) !important;
		right: 100px !important;
		border-radius: 16px;
		overflow: hidden;
	}
	.menu__item{
		position: relative;

		.dropdown__controls{
			display: flex;
		}
		.menu__link{
			padding: 17px 20px;
			position: relative;
			display: block;
			font-size: 16px;
			line-height: 16px;

			span{
				position: relative;
				z-index: 500;
			}

			&:hover{
				text-decoration: none;
				color: #d64d00;
			}
		}
	}
	.segura__destaques{
		flex-grow: 1;
		position: relative;

		&:after{
			width: 100vw;
			left: -25px;
			z-index: -1;
			content: '';
			position: absolute;
			background-color: #ededed;
			top: 0px;
			height: 100%;
		}
	}
}
.mbl__logo{
	max-width: 200px;
	margin-left: 10px;
}
@include media-breakpoint-down (md) {
	.topo__main{
		position: fixed;
		top: 0px;
		left: -250px;
		width: 250px;
		z-index: 1080;
		height: 100%;
		overflow-y: auto;
		background-color: #fff;
		transition: all 0.5s linear;
		flex-direction: column;
		display: flex;

		.main__meio{
			order: 1;

			.segura__pesquisa{
				display: none;
			}
		}
		.segura__categorias{
			order: 2;

			.container{
				display: flex;
				flex-direction: column;

				.btn__toggle__categ{
					order: 2;
					border-radius: unset !important;
				}
				.segura__destaques{
					order: 1;
				}
				.categoria__expanded{
					order: 3;

					a{
						display: block;
						padding: 10px 10px;
						border-bottom: 1px solid #2b3f38;
						color: #2b3f38;
					}
					.dropmenu{
						padding-left: 0px;
						list-style: none;

						a{
							background-color: #2b3f38;
							color: #fff;
							border-bottom: 1px solid #ededed;
						}
					}
				}
			}
		}
		.main__cima{
			order: 3;
		}

		.container{
			padding: 0px;
		}

		&.shown{
			left: 0px;
		}
		.segura__login__topo{
			display: none;
		}
		.segura__carrinho__topo{
			display: none;
		}
	}
	.main__logo{
		text-align: center;
		padding: 20px;
	}
	.topo__mobile{
		background-color: #fff;
		padding: 10px 0px;
		border-bottom: 2px solid #2b3f38;
		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.mbl__toggler{
		border: none;
		background-color: transparent;
		color: #2b3f38;
		font-size: 16px;
		line-height: 16px;
		padding: 0px 8px;
	}
	.segura__login__topo{
		.login__icone,
		.login__texto{
			display: none;
		}
		.mobile__icone{
			padding: 0px 8px;
			border-left: 1px solid rgba(#2b3f38, 0.3);
			color: #2b3f38;
		}
	}
	.segura__carrinho__topo{
		position: relative;
		.carrinho__icone{
			display: none;
		}
		.carrinho__texto{
			display: none;
		}
		.mobile__icone{
			padding: 0px 5px;
			border-left: 1px solid rgba(#2b3f38, 0.3);
			color: #2b3f38;
		}
	}
	.mbl__left,
	.mbl__right{
		display: flex;
		align-items: center;
	}
	.logado__menu{
        min-width: 200px;
        max-width: 100%;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#666666, 0.6);
        right: -3px !important;
		left: unset !important;
		top: calc(100% + 10px)!important;
		transform: unset !important;
    }
	.logado__toggler{
		&[aria-expanded="true"] {
			&:after{
				display: block;
			}
		}

		&:after{
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 10px solid #007d3a;
			content: '';
			position: absolute;
			right: 7px !important;
			bottom: -10px;
			display: none;
			z-index: 100;
		}
	}
	.main__menu{
		background-color: #fff;



		.menu__link{
			padding: 10px;
			display: flex;
			align-items: center;
			font-family: 'interregular';
			border-bottom: 1px solid #2b3f38;
			color: #2b3f38;

			.menu__link__icone{
				margin-right: 5px;
				padding-bottom: 2px;
			}
		}
	}
	.dropdown__controls{
		position: relative;

		button{
			width: 20px;
			height: 20px;
			position: absolute;
			top: calc(50% - 10px);
			right: 10px;
			border: none;
			background-color: #2b3f38;
			color: #fff;
			border-radius: 10px;
			padding: 3px 5px 1px 5px;
			font-size: 12px;
			line-height: 12px;
			transition: all 0.3s linear;
			display: flex;
			align-items: center;
			justify-content: center;

			&[aria-expanded="true"]{
				transform: rotate(90deg);
			}
		}
	}
	.main__cima{
		color: #2b3f38;
		text-align: center;
		padding: 10px;

		.contato__topo{
			margin-bottom: 20px;

			.contato__topo__icone{
				margin-right: 0px;
				margin-bottom: 5px;
			}
		}
	}
}
