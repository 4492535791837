.banner__carousel{
	max-width: 1920px;
	width: 100%;
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;

	.carousel-inner{
		max-width: 1920px;
		width: 100%;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
	.carousel-indicators{
		bottom: 20px;
		li{
			display: flex;
			align-items: center;
			position: relative;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			opacity: 1;
			border: none;
			margin: 0px 3px;
			transition: all 0.3s linear;

			&.active{
				margin-right: 75px;
				&:before{
					width: 60px;
				}
				&:after{
					opacity: 1;
				}
			}

			&:before{
				height: 2px;
				width: 0px;
				content: '';
				position: absolute;
				left: 20px;
				background: #fff;
				opacity: 0.5;
				transition: all 0.3s linear;
			}

			&:after{
				width: 16px;
				height: 16px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border: 1px solid #fff;
				border-radius: 50%;
				content: '';
				opacity: 0;
			}
		}
	}
}
.info__card{
	display: flex;
	align-items: center;


	.info__icone{
		width: 60px;
		height: 60px;
		min-width: 60px;
		background-color: #2b3f38;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		margin-right: 10px;
	}
}
.segura__info{
	padding: 52px 0px 54px 0px;
	@include media-breakpoint-up (lg) {
		display: flex;
		justify-content: space-between;

		.info__card{
			min-width: 25%;
		}
	}
}
.info__conteudo{
	font-size: 16px;
	line-height: 20px;

	.info__conteudo__titulo{
		font-family: interbold;
	}
}

.titulo__secao{
	font-family: 'interbold';
	font-size: 30px;
	line-height: 34px;
	color: #000000;
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 40px;

	&:after{
		width: auto;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		margin-left: 30px;
		height: 2px;
		background: #014e2a;
		content: "";
		display: block;
	}
}
.produtos,
.destaques,
.vistos{
	@include media-breakpoint-up (lg) {
		.titulo__secao{
			padding-right: 120px;
		}
		.owl-nav{
			margin-top: -75px;
			margin-bottom: 50px !important;
		}
	}
}
.produto__card{
	display: block;
	width: 100%;
	max-width: 255px;
	border: 1px solid #d1d1d1;
	border-radius: 26px;
	padding: 8px;
	background-color: #fff;
	padding-bottom: 20px;
	margin-left: auto;
	margin-right: auto;

	&:hover{
		.segura__botoes__card{
			opacity: 1;
		}
	}

	.produto__card__conteudo{
		display: block;
		padding: 0px 14px;
	}

	.produto__card__titulo{
		font-family: interbold;
		font-size: 16px;
		line-height: 16px;
		min-height: 32px;
		margin-bottom: 5px;
	}
	.produto__card__valor{
		display: flex;
		align-items: flex-end;
		font-size: 16px;
		line-height: 16px;

		.produto__valor__promocao{
			font-size: 12px;
			text-decoration: line-through;
			margin-right: 5px;
			line-height: 12px;
			margin-bottom: 1px;
		}
		.produto__valor__total{
			font-family: interbold;
		}
	}

	.produto__card__imagem{
		position: relative;
		margin-bottom: 15px;

		.produto__card__tags{
			position: absolute;
			top: 10px;
			left: 10px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			font-family: interbold;
			font-size: 14px;
			line-height: 14px;
			z-index: 600;

			.produto__card__tag{
				background-color: #ff0000;
				color: #fff;
				display: inline-flex;
				min-width: 55px;
				min-height: 20px;
				align-items: center;
				justify-content: center;
				text-align: center;
				border-radius: 10px;
				padding: 3px;
				margin-bottom: 5px;
			}
		}
	}
	.segura__botoes__card{
		position: absolute;
		display: flex;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 0px;
		left: 0px;
		transition: all 0.3s linear;

		@include media-breakpoint-up (lg) {
			opacity: 0;
		}

		& > * {
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fff;
			color: #2b3f38;
			transition: all 0.3s linear;
			box-shadow: 0px 0px 3px rgba(#2b3f38, 0.3);
			border-radius: 50%;
			margin: 5px;
			border: none;

			&:focus{
				outline: none;
			}

			&:hover{
				background-color: #2b3f38;
				color: #fff;
			}
		}
	}
}

.produtos__owl.owl-carousel{
	display: flex;
	flex-direction: column;

	.owl-nav{
		order: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 20px;

		button.owl-prev,
		button.owl-next{
			width: 40px;
			height: 40px;
			background-color: transparent;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #2b3f38;
			position: relative;
			color: #2b3f38;
			margin-left: 10px;
			transition: all 0.3s linear;

			&:hover{
				background-color: #2b3f38;
				color: #fff;
				text-decoration: none;
			}

			i{
				position: relative;
				z-index: 300;
			}
		}
	}
	.owl-stage-outer,
	.owl-stage{
		order: 2;
	}
}
.produtos{
	padding-bottom: 82px;
}
.banner__link{
	display: block;
	max-width: 542px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	border-radius: 30px;
	overflow: hidden;
}
.banner__links{
	margin-bottom: 72px;
	position: relative;
	z-index: 100;
	.container{
		position: relative;

		&:before{
			background-image: url(../images/detalhe-banners.png);
			background-repeat: no-repeat;
			background-size: auto;
			background-position: center;
			content: '';
			position: absolute;
			z-index: -1;
			width: 114px;
			height: 195px;
			top: -50px;
			left: -60px;
		}
	}
}
.destaques{
	position: relative;
	padding-bottom: 92px;
	z-index: 50;

	.titulo__secao{
		color: #fff;

		&:after{
			background-color: #fff;
		}
	}

	.produtos__owl.owl-carousel{
		.owl-nav{
			button{
				border-color: #fff;
				color: #fff;

				&:hover{
					background-color: #fff;
					color: #014e2a;
				}
			}
		}
	}

	&:after{
		width: 100%;
		height: 800px;
		min-height: 100%;
		content: '';
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-color: #193C34;
		bottom: 0px;
		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		z-index: -5;
	}
}
.vistos{
	padding-top: 76px;
	padding-bottom: 76px;
}
.news__titulo{
	color: #000000;
	font-family: interbold;
	font-size: 35px;
	line-height: 37px;
	margin-bottom: 5px;
}
.perguntas{
	padding-top: 40px;
}
.news__sub{
	font-size: 16px;
	line-height: 20px;
	color: #666666;
	margin-bottom: 20px;
}
.news__form{
	display: flex;
    overflow: hidden;
    background-color: #fff;
    align-items: center;
	border-radius: 25px;
	border: 1px solid #d1d1d1;

    .news__btn{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:#2b3f38;
        color: #fff;
        min-width: 40px;
        width: 40px;
        height: 40px;
        border: none;
		border-radius: 50%;
		position: relative;
		margin: 5px;
    }
    .news__input{
        display: block;
        width: 100%;
        font-family: 'interregular';
        font-size: 13px;
        line-height: 17px;
        padding: 17px 14px 16px 30px;
        border: none;
        background-color: #fff;
        color: #666666;
        border: none;
        border-radius: 25px;

        &::placeholder{
            color: #666666;
			font-size: 13px;
			line-height: 17px;
        }

        &:focus{
            outline: none;
        }
    }
}
.pergunta__card{
	border-radius: 25px;
	border: 1px solid #d1d1d1;
	margin-bottom: 10px;

	.pergunta__btn{
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 13px;
        line-height: 17px;
        padding: 5px 5px 5px 30px;
		background-color: #fff;
        color: #666666;
		border-radius: 25px;
		width: 100%;
		position: relative;
		border: none;
		font-family: interbold;

		&:after{
			width: 100%;
			height: 100%;
			top: -1px;
			left: 0px;
			content: '';
			position: absolute;
			border-radius: 25px;
			border: 1px solid #d1d1d1;
			opacity: 0;
		}

		&[aria-expanded="true"]{
			.icone__pergunta{
				transform: rotate(-180deg);
				background-color: #193C35;
			}
			&:after{
				opacity: 1;
			}
		}
	}
	.icone__pergunta{
		display: flex;
        align-items: center;
        justify-content: center;
        background-color:#2b3f38;
        color: #fff;
        min-width: 40px;
        width: 40px;
        height: 40px;
        border: none;
		border-radius: 50%;
		position: relative;
		transition: all 0.3s linear;
	}
	.pergunta__conteudo{
		padding: 20px 30px 30px 30px;
		font-size: 13px;
		line-height: 16px;
	}
}
.segura__index__bottom{
	padding-bottom: 96px;
}
@include media-breakpoint-down (md) {
	.info__card{
		justify-content: center;
		margin-bottom: 20px;
		flex-direction: column;
		text-align: center;

		.info__icone{
			margin-right: 0px;
			margin-bottom: 5px;
		}
	}
	.banner__link{
		margin-bottom: 30px;
	}
	.segura__index__bottom{
		padding-bottom: 30px;
		.col-lg-6{
			margin-bottom: 50px;
		}
	}
}
