.perdeu__form{
    max-width: 920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.perdeu__texto{
    color: #353535;
    margin-bottom: 10px;
}
.perdeu__btns{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
	padding-top: 20px;

    & > * {
        margin: 5px;
    }
}
.check__contato{
    &.segura__check{
        padding-top: 10px;

        .check__label__cadastro{
            font-weight: 400;
            margin-bottom: 10px;

            &:before{
                top: 10px;
            }
            &:after{
                top: 10px;
            }
        }
    }
}
.contato__btn{
    padding-top: 10px;
}
.perdeu__form{
    .titulo__interna{
        margin-bottom: 40px;
    }
}
