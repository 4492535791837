.mapa-brasil{
	filter: drop-shadow(0 0 4px rgba(#fff,0.4));

	g {
		fill: #dbdbdb;
        transition: all 0.3s linear;
        cursor: pointer;

		&:hover{
			fill: darken(#d2d3d5, 10%);
		}
		&.active{
			fill: #014e2a;
		}
	}
}
select#estados{
    background-color: #e4e4e4;
    color: #666666;
    font-size: 13px;
    line-height: 17px;
    border: none;
    width: 100%;
    display: block;
    height: auto;
    padding: 14px 40px 12px 20px;
    position: relative;
    appearance: none;


    &:focus{
        outline: none;
    }

}
.segura__select{
    position: relative;
	margin-bottom: 50px;

    &:after{
        content: '';
        border-top: 6px solid #8e8e8e;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        position: absolute;
        z-index: 300;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
}
@include media-breakpoint-down (md) {
	.mapa-brasil{
        pointer-events: none;
    }
    .segura__select{
        margin-bottom: 20px;
    }
}
