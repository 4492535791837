.segura__opcoes__pagamento{
	.opcao__pagamento{
		cursor: pointer;
		.opcao__pagamento__input{
			position: fixed;
			left: -200vw;
			&:checked{
				~ .opcao__pagamento__label{

					// &:before{
					// 	border-color: #353535;
					// 	background-color: #fff;
					// }
					&:after{
						opacity: 1;
					}
					&:before{
						border-color: #007d3a;
					}
				}
			}
		}
		.opcao__pagamento__label{
			position: relative;
			border: 1px solid #d1d1d1;
			border-radius: 25px;
			padding-left: 66px;
			background-color: #ededed;
			overflow: hidden;
			min-height: 142px;
			display: block;

			&:before{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 22px;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				border: 2px solid #007D3A;
				transition: all 0.3s linear;
				background-color: transparent;
				z-index: 10;
			}

			&:after{
				content: '';
				position: absolute;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				border: 4px solid #ededed;
				background-color: #007D3A;
				top: 50%;
				left: 22px;
				transform: translateY(-50%);
				transition: all 0.3s linear;
				opacity: 0;
				z-index: 5;
			}

			.pagamento__opcao__card__segura{
				background-color: #fff;
				display: flex;
				align-items: center;
				min-height: 142px;
			}
		}
	}
	.pagamento__opcao__titulo{
		color: #000;
		font-size: 20px;
		line-height: 24px;
		font-family: interbold;
		margin-bottom: 15px;
	}
	.pagamento__opcao__imagem{
		border-radius: 10px;
		overflow: hidden;
		margin-right: 20px;
		margin-left: 30px;
	}
	.pagamento__opcao__conteudo{
		font-family: interbold;
		color: #666666;
		font-size: 14px;
		line-height: 16px;

		span{
			font-family: intersemibold;
		}
	}
}
.subtitulo__finalize{
	font-family: interbold;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 30px;
	color: #000;
}
.finalizar__coluna{
	background-color: #ededed;
	color: #353535;
	padding-left: 26px;
	padding-right: 26px;
	border-radius: 25px;
	margin-bottom: 15px;
}
.finalizar__item{
	padding: 25px 0px;
	border-bottom: 1px solid #d1d1d1;
}
.finalizar__titulo{
	font-family: interbold;
	font-size: 16px;
	line-height: 20px;
	color: #000;
}
.finalizar__produto{
	display: flex;
	justify-content: space-between;
	margin-bottom: 7px;

	& > * {
		&:nth-child(1) {
			width: 45%;
		}
		&:nth-child(2) {
			width: 20%;
			text-align: center;
		}
		&:nth-child(3) {
			width: 35%;
			text-align: right;
		}
	}
}
.finalizar__valores{
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #000;
	font-family: interbold;
	font-size: 16px;
	line-height: 20px;

	span{
		&:nth-child(2) {
			color: #666666;
		}
	}
}
