.interna{
    padding: 100px 0px 110px 0px;
}
.titulo__interna{
    font-family: 'interbold';
    font-size: 30px;
    line-height: 34px;
    color: #353535;
    margin-bottom: 38px;
	position: relative;
	padding-bottom: 10px;

	&:before{
		background: #007d3a;
		content: '';
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
		height: 5px;
	}
}
.minha__conta__nav{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    a{
        display: block;
        padding: 15px 10px;
        font-size: 15px;
        line-height: 15px;
        max-width: 300px;
        border-bottom: 1px solid rgba(#353535, 0.4);
        width: 100%;
		text-align: center;
    }
}
.conta__interna{
	.titulo__interna{
		text-align: center;
	}
}
.conteudo__btn{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	color: #007d3a;
	min-width: 250px;
	height: 50px;
	transition: all 0.3s linear;
	font-family: 'interbold';
	font-size: 14px;
	line-height: 14px;
	border: 2px solid #007d3a;
	border-radius: 25px;
	position: relative;

	&.alt{
		background-color: #007d3a;
		color: #fff;
		border-color: #fff;

		&:hover{
			background-color: #fff;
			color: #007d3a;
			border-color: #007d3a;
		}
	}

	&.alt2{
		background-color: #A60000;
		color: #fff;
		border-color: #A60000;

		&:hover{
			background-color: #7C0000;
			color: #fff;
			border-color: #7C0000;
		}
	}

	span{
		position: relative;
		z-index: 200;
	}

	&.voltar__btn{
		min-width: 154px;
	}

	&:focus{
		outline: none;
	}

	&:hover{
		color: #fff;
		text-decoration: none;
		background: #007d3a;
	}
}
.custom__check__input{
	position: fixed;
	left: -100vw;

	&:checked{
		& ~ .custom__check__label{
			&:after{
				opacity: 1;
			}
		}
	}
}
.custom__check__label{
	position: relative;
	padding-left: 24px;
	cursor: pointer;
	color: #666666;
	font-family: interregular;
	font-weight: normal;
	margin-left: 30px;

	a{
		text-decoration: underline;
	}

	&:before{
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0px;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		border: 2px solid #007d3a;
		transition: all 0.3s linear;
	}

	&:after{
		content: '';
		position: absolute;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #007d3a;
		top: 50%;
		left: 4px;
		transform: translateY(-50%);
		transition: all 0.3s linear;
		opacity: 0;
	}
}
.checkbox__custom{
	padding-top: 10px;
}
.segura__contato__btn{
	padding-top: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.paginacao{
	display: flex;
	justify-content: flex-start;
    flex-wrap: wrap;
    border-radius: none;
    border: none;
    list-style: none;
    padding-left: 0px;
	padding-top: 30px;

	.paginacao__item{
		a{
            font-family: 'interbold';
            font-size: 15px;
            line-height: 15px;
            color: #666666;
			border: 1px solid #666666;
            display: flex;
            width: 35px;
			min-width: 35px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            height: 35px;
            border-radius: 50%;
            position: relative;
            transition: all 0.3s linear;
            margin: 0px 2px;
            padding: 0px;

            &:focus{
                box-shadow: none;
            }
        }

        &.active{
           a{
				background: #2b3f38;
				border-color: #2b3f38;
                color: #fff;
           }
        }

		&:hover{
			a{
                text-decoration: none;
            }
		}
	}
}
.revender__img{
	margin-bottom: 25px;
	text-align: center;
}
.revender__form{
	margin-top: 40px;
	padding-top: 40px;
	border-top: 5px solid #ededed;

	.cadastro__titulo{
		margin-bottom: 28px;
	}
	.form-group{
		margin-bottom: 10px;
	}
}
.encontrar__locals{
	.encontrar__item{
		display: flex;
		margin-bottom: 34px;

		.encontrar__item__icone{
			width: 40px;
			height: 40px;
			min-width: 40px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 15px;
			background-color: #014e2a;
			font-size: 20px;
			line-height: 20px;
			color: #fff;
		}

		.encontrar__item__conteudo{
			padding-top: 10px;
		}

		.encotrar__item__titulo{
			font-family: interbold;
			color: #666666;
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 7px;
		}
	}
}
.row__encontrar{
	align-items: center;
}
.encontrar__titulo{
	font-size: 20px;
	line-height: 24px;
	color: #000;
	font-family: interbold;
	margin-bottom: 28px;
}
.sub__interna{
	font-family: interbold;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 40px;
	color: #000;
}
@include media-breakpoint-up (lg) {
	.select__estado{
		display: none;
	}
}
.encontrar__locals{
	@include media-breakpoint-down (md) {
		padding-top: 20px;
	}
}
.modal__link{
	.modal-dialog{
		margin-left: auto;
		margin-right: auto;
		max-width: 920px;
		width: 100%;
		.modal-content{
			background-color: transparent;
			border: none;
		}
		.modal-body{
			background-color: transparent;
			border: none;
			padding: 0px;
			position: relative;

			button{
				position: absolute;
				top: 20px;
				right: 20px;
				font-size: 25px;
				background-color: transparent;
				border: none;
				line-height: 28px;
				color: #2b3f38;

				&:focus{
					outline: none;
				}
			}
		}
	}
}
.contato__btn__center{
	display: flex;
	justify-content: center;
}
.contato__check{
	&.checkbox__custom{
		.custom__check__label{
			&:after{
				background-color: #007D3A;
			}
			&:before{
				border-color: #007D3A;
			}
		}
	}
}
