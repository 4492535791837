
.carrinho__header{
	display: flex;
	justify-content: center;
	font-size: 14px;
	line-height: 18px;
	color: #fff;
	background-color: #014e2a;
	font-family: 'interbold';
	border-radius: 25px 25px 0px 0px;
	overflow: hidden;
}
.carrinho__produto{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
}
.carrinho__quantidade{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
	text-align: center;
}
.carrinho__valor__uni{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
	text-align: center;
    font-family: 'interbold';
}
.carrinho__valor__total{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
	text-align: center;
    font-family: 'interbold'
}
.carrinho__produto__card{
	display: flex;
	position: relative;
}
.btn__excluir{
	position: absolute;
	top: -8px;
	left: -15px;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background-color: rgba(#A60000, 1);
	z-index: 10;
	border: none;
	font-size: 10px;
	line-height: 10px;
	color: #fff;
	transition: all 0.3s linear;
	opacity: 1;

	&:hover{
		color: #fff;
		opacity: 0.65;
	}
	&:focus{
		outline: none;
	}

	span{
		padding-top: 2px;
		padding-left: 1px;
	}
}
.carrinho__produto__img{
	margin-right: 20px;
	min-width: 91px;
}
.carrinho__item{
	display: flex;
	font-family: 'interbold';
	border-left: 2px solid #ededed;
	border-right: 2px solid #ededed;


	&:last-child{
		border-bottom: 2px solid #ededed;
	}
}
.carrinho__produto__desc{
    padding-top: 10px;
    font-family: 'interregular';
    color: #353535;
}
.input-group{
	display: flex;
    justify-content: center;

    .input-group-append{
         &:nth-child(1){
            button{
                padding-bottom: 5px;
            }
        }
    }
}
.carrinho__card{
	border: 2px solid #d1d1d1;
	padding: 25px 15px;
	border-radius: 15px;
	margin-left: 0px;
	margin-right: 0px;
	margin-bottom: 30px;
}
.btn__quantidade{
	border: none;
	display: flex;
	width: 34px;
	height: 38px;
	align-items: center;
	justify-content: center;
    background-color: #313131;
	border: 2px solid #313131;
	color: #fff;
	transition: all 0.3s linear;
    font-family: 'interregular';
    font-size: 20px;
    line-height: 20px;
    padding-top: 2px;

	&:hover{
		text-decoration: none;
		background-color: #353535;
		color: #fff;
		border: 2px solid #353535;
	}

	&:focus{
		outline: none;
	}
}
.carrinho__valor__promocao{
	font-size: 14px;
	line-height: 18px;
	margin-right: auto;
	margin-left: auto;
    text-align: left;
    color: #353535;
	text-decoration: line-through;
	font-family: 'interbold';
}
.carrinho__valor__unidade{
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	margin-right: auto;
	margin-left: auto;
    font-family: 'interbold';
    color: #353535;
}
.numero__valor__unidade{
	font-size: 20px;
	line-height: 20px;
	margin-left: 3px;
    font-family: 'interbold';
    color: #353535;
}
.carrinho__item{
	.carrinho__produto{
		padding-top: 28px;
		padding-bottom: 20px;
		border-right: 4px solid #ededed;
	}
	.carrinho__valor__uni{
		padding-top: 28px;
		padding-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		border-right: 4px solid #ededed;
	}
	.carrinho__quantidade{
		padding-top: 28px;
		padding-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		border-right: 4px solid #ededed;
	}
	.carrinho__valor__total{
		padding-top: 28px;
		padding-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: 'interregular';
		font-size: 20px;
		line-height: 20px;
        flex-direction: column;
        color: #353535;
	}
}

@include media-breakpoint-up (lg) {
	.carrinho__produto{
		width: 38%;
	}
	.carrinho__quantidade{
		width: 23%
	}
	.carrinho__valor__uni{
		width: 22%;
	}
	.carrinho__valor__total{
		width: 17%;
	}
}

@include media-breakpoint-down (md) {
	.carrinho__item{
		flex-direction: column;
	}
	.carrinho__header{
		display: none;
	}
	.carrinho__produto__card{
		flex-direction: column;
		text-align: center;
	}
	.carrinho__produto__img{
		margin-right: 0px;
	}
	.carrinho__item{
		border-right: 1px solid #ededed;
		border-bottom: 1px solid #ededed;
		border-left: 1px solid #ededed;
		padding: 0px;
		margin-bottom: 50px;

		.carrinho__produto,
		.carrinho__quantidade,
		.carrinho__valor__uni,
		.carrinho__valor__total{
			position: relative;
			border: none;
			padding-top: 0px;
			padding-bottom: 10px;
			padding-left: 0px;
			padding-right: 0px;

			&:before{
				content: attr(data-text);
				display: block;
				text-align: center;
				width: 100%;
				padding: 10px 20px;
				color: #fff;
				font-family: 'interbold';
				font-size: 16px;
				line-height: 20px;
				background-color: #353535;
				margin-bottom: 20px;
			}
		}
	}
	.btn__excluir{
		position: absolute;
		left: 30px;
	}
}
.titulo__quantidade__carrinho{
	font-family: 'interbold';
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	margin-bottom: 5px;
	color: #666666;
}
.quantidade__valor__numero{
	font-family: 'interbold';
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	margin-top: 10px;
	color: #666666;
}
.carrinho__produto__titulo{
	font-family: interbold;
	margin-bottom: 16px;
	max-width: 210px;
	width: 100%;
	color: #666666;
}
.carrinho__produto__tamanho{
	span{
		font-family: interbold;
	}
}
.carrinho__simulacao__desc{
	font-size: 16px;
	line-height: 20px;
	font-family: interbold;
	margin-bottom: 20px;
	color: #000;
}
.simulacao__cep__input{
	display: block;
	width: 100%;
	height: auto;
	padding: 16px 20px 16px 20px;
	background-color: #fff;
	color: #353535;
    font-family: 'interregular';
	font-size: 14px;
	line-height: 18px;
	border-radius: 25px;
	border: none;
	border: 1px solid #d1d1d1;

	&:focus{
		outline: none;
	}
}
.simulacao__cep__desc{
	text-align: center;
	padding-top: 20px;
}
.frete__aviso{
	background-color: #009045;
	color: #fff;
	font-family: interbold;
	font-size: 13px;
	line-height: 15px;
	display: flex;
	justify-content: center;
	min-height: 30px;
	padding: 10px;
	border-radius: 20px;

	.icon{
		margin-right: 5px;
	}
}
.total__carrinho__secao{
	background-color: #ededed;
	border-radius: 15px;
	padding-bottom: 30px;
	padding-left: 30px;
	padding-right: 30px;


	.col-6{
		&:nth-child(1) {
			padding-left: 0px;
		}
		&:nth-child(2) {
			padding-right: 0px;
		}
	}
	.col-5{
		padding-left: 0px;
	}
	.col-7{
		padding-right: 0px;
	}
}
.parcelamento__carrinho{
	font-size: 12px;
	line-height: 14px;
	font-family: interregular;
}
.item__total__carrinho{
	padding-top: 26px;
	padding-bottom: 26px;
	border-top: 1px solid #353535;
	color: #000;
	font-family: interbold;
	font-size: 16px;
	line-height: 20px;
	margin-right: 0px;
	margin-left: 0px;

	&.row{
		& > *{
			&:nth-child(2) {
				color: #666666;
			}
		}
	}

	&:nth-child(1) {
		border-top: unset !important;
	}

	.col-6{
		&:nth-child(2) {
			text-align: right;
		}
	}
	.col-7{
		text-align: right;
	}

	.conteudo__btn{
		min-width: 110px;
		margin-left: -15px;
	}
	.simulacao__cep__input{
		padding-right: 40px;
	}
}
.carrinho__botoeszoes{
	.conteudo__btn{
		margin: 10px 0px;
		border-color: #FF5C00;
		color: #FF5C00;

		&:hover{
			background-color: #FF5C00;
			color: #fff;
		}

		&.alt{
			border-color: #FF5C00;
			color: #fff;
			background-color: #FF5C00;

			&:hover{
				color: #FF5C00;
				background-color: #fff;
			}
		}
	}
}
.total__carrinho__form{
	display: flex;
	align-items: center;
	background-color: #fff;
	border-radius: 25px;
	overflow: hidden;
	position: relative;

	input{
		border-radius: 25px 0px 0px 25px;
		padding-right: 200px;
	}

	.conteudo__btn{
		background-color: #2b3f38;
		color: #fff;
		width: 110px;
		position: absolute;
		right: 0px;
		border-color: #2b3f38;
	}
}
.carrinho__simulacao__cep{
	display: flex;
	align-items: flex-start;
	width:  100%;

	@include media-breakpoint-down (sm) {
		flex-direction: column;
	}

	.carrinho__simulacao__form{
		width: 100%;
		display: block;
		margin-right: 30px;
	}
}
.simulacao__cep__link{
	text-align: right;
	display: block;
	margin-top: 5px;
	color: #FF5C00;
	text-decoration: underline;
}
.col__carrinho__simulacao{
	padding-top: 25px;
	padding-left: 30px;
	padding-right:30px;
	padding-bottom: 20px;
	position: relative;
	border: 2px solid #d1d1d1;
	position: relative;
	border-radius: 15px;

	@include media-breakpoint-down (md) {
		margin-bottom: 30px;
	}
}
.inline-group{
	border: none;
	max-width: 170px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;

	.input-group-prepend,
.input-group-append{
	margin-left: unset !important;
	margin-right: unset !important;
	button{
		height: 23px;
		width: 23px;
		color: #fff;
		transition: all 0.3s linear;
		border-radius: 5px !important;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		font-size: 12px;
		line-height: 12px;
		background: #FF5C00;
		border: 2px solid #FF5C00;
		color: #fff;
		padding: 0px !important;

		i{
			position: relative;
			z-index: 50;
		}

		&:hover{
			color: #FF5C00;
			background: #fff;
		}

		&:focus{
			outline: none;
			box-shadow: none;
		}
	}
}

input{
	height: 34px;
	max-width: 34px;
	width: 34px;
	border: none;
	background-color: #fff;
	color: #666666;
	text-align: center;
	font-family: 'interregular';
	border: 1px solid #dcdcdc;
	padding: 0px;
	pointer-events: none;
	border-radius: 5px !important;
	margin: 0px 5px;

	&::placeholder{
		color: #666666;
	}

	&:focus{
		background-color: #fff;
		color: #666666;
	}
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

}
.carrinho__aviso{
	background-color: #29B300;
	color: #fff;
	padding: 10px;
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	border-radius: 3px;

	&.perigo{
		background-color: #CD0000;
	}

	&.perigo-comentario{
		background-color: #CC0000;
		margin-bottom: 5px;
	}

	.fas{
		font-size: 30px;
		line-height: 30px;
		margin-right: 8px;
	}
}
.col__carrinho__simulacao{
	.checkbox__custom{
		label.custom__check__label{
			font-family: intersemibold !important;

			&:before{
				border-color: currentColor;
			}
		}
		input{
			&:checked{
				& ~ label{
					&:before{
						border-color: #007D3A;
					}
				}
			}
		}
	}

	&.frete__col{
		margin-top: 20px;
		.carrinho__simulacao__desc{
			margin-bottom: 10px;
		}
	}
}
